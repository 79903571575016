import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../common/Layout';
import {
  getTaskById,
  updateTask,
  fetchTaskLifestyleConfig,
  deleteTask,
} from '../../firebase/firetstore';
import { auth } from '../../firebase/firebaseConfig';
import SideMenu from '../common/SideMenu';
import { useAuth } from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../common/Loader';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import {
  FaDownload,
  FaTrash,
  FaUpload,
  FaFile,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaEdit,
} from 'react-icons/fa';
import { uploadTaskFiles } from '../../services/storageService';
import api from '../../api';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  getFirestore
} from 'firebase/firestore';
import { logError } from '../../services/errorLogService';

const TaskDetails = () => {
  const { taskId } = useParams();
  const { user } = useAuth();
  const db = getFirestore();
  const navigate = useNavigate();
  const location = useLocation();
  const [task, setTask] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTask, setEditedTask] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [estimationUnit, setEstimationUnit] = useState('h');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const fileInputRef = useRef(null);
  const [expandedImage, setExpandedImage] = useState(null);
  const [commentImage, setCommentImage] = useState(null);
  const [commentImagePreview, setCommentImagePreview] = useState(null);
  const commentImageInputRef = useRef(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [editedCommentImage, setEditedCommentImage] = useState(null);
  const [editedCommentImagePreview, setEditedCommentImagePreview] =
    useState(null);
  const editCommentImageInputRef = useRef(null);

  const [isAttachDeleteModalOpen, setIsAttachDeleteModalOpen] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(0);
  const [isAttachExisting, setIsAttachExisting] = useState(0);
  const [isCommentDeleteModalOpen, setIsCommentDeleteModalOpen] =
    useState(false);
  const [commentImageDeleteType, setCommentImageDeleteType] =
    useState('comment');
  const [developers, setDevelopers] = useState([]);

  const fetchDevelopers = async (projectName) => {
    try {
      const usersCollection = collection(db, 'user_details');
      const usersQuery = query(
        usersCollection,
        where('role', 'in', ['developer', 'lead', 'manager']),
        where('projects', 'array-contains', projectName)
      );
      const usersSnapshot = await getDocs(usersQuery);
      const developersList = usersSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .map((developer) => ({
          email: developer.email,
          name: `${developer.first_name} ${developer.last_name} (${developer.email})`,
          isCurrentUser: developer.email === user.email,
        }));

      setDevelopers(developersList);

    } catch (error) {
      await logError('firebase_fetchDevelopers', error.message, {
        collection: 'user_details',
        projectName,
        user: auth.currentUser?.email,
        component: 'TaskDetails'
      });
      toast.error('Failed to fetch developers');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!editedTask.title.trim()) newErrors.title = 'Title is required';
    if (!editedTask.description.trim())
      newErrors.description = 'Description is required';
    if (!editedTask.status) newErrors.status = 'Status is required';
    if (!editedTask.estimation) newErrors.estimation = 'Estimate is required';
    if (isNaN(Number(editedTask.estimation)))
      newErrors.estimation = 'Estimate must be a number';
    if (!editedTask.project_name.trim())
      newErrors.project_name = 'Project Name is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEditComment = (commentId, commentText, commentImageUrl) => {
    setEditingCommentId(commentId);
    setEditedCommentText(commentText);
    setEditedCommentImage(commentImageUrl);
    setEditedCommentImagePreview(commentImageUrl);
  };

  const handleEditCommentImageSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateCommentImage(file)) {
        setEditedCommentImage(file);
        setEditedCommentImagePreview(URL.createObjectURL(file));
      } else {
        // Clear the file input if validation fails
        if (editCommentImageInputRef.current) {
          editCommentImageInputRef.current.value = '';
        }
      }
    }
  };

  const handleRemoveEditedCommentImage = () => {
    setEditedCommentImage(null);
    setEditedCommentImagePreview(null);
  };

  const handleCommentImageSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateCommentImage(file)) {
        setCommentImage(file);
        setCommentImagePreview(URL.createObjectURL(file));
      } else {
        // Clear the file input if validation fails
        if (commentImageInputRef.current) {
          commentImageInputRef.current.value = '';
        }
      }
    }
  };

  const handleRemoveCommentImage = () => {
    setCommentImage(null);
    setCommentImagePreview(null);
    if (commentImageInputRef.current) {
      commentImageInputRef.current.value = '';
    }
  };

  useEffect(() => {
    const fetchTaskAndConfig = async () => {
      setIsLoading(true);
      try {
        const collection = 'configs';
        const document = 'task_life_cycle';
        const path = `task_list`;
        const [taskData, configData] = await Promise.all([
          getTaskById(taskId, path),
          fetchTaskLifestyleConfig(collection, document),
        ]);
        setTask(taskData);
        setEditedTask(taskData);
        setStatusOptions(configData.task_life_cycle || []);

        setStartTime(
          taskData.start_time
            ? formatDateForInput(taskData.start_time.seconds * 1000)
            : ''
        );
        setEndTime(
          taskData.end_time
            ? formatDateForInput(taskData.end_time.seconds * 1000)
            : ''
        );
        fetchDevelopers(taskData.project_name);
        setTask((prev) => ({ ...prev, estimation: taskData.estimation }));
        setEstimationUnit(taskData.estimation_unit.toLowerCase());
      } catch (error) {
        // await logError('firebase_fetchTaskAndConfig', error.message, {
        //   taskId,
        //   configCollection: 'configs',
        //   configDocument: 'task_life_cycle',
        //   taskPath: 'task_list',
        //   user: auth.currentUser?.email,
        //   component: 'TaskDetails'
        // });
      } finally {
        setIsLoading(false);
      }
    };

    fetchTaskAndConfig();
  }, [taskId]);

  useEffect(() => {
    if (task && task.attachments) {
      setAttachments(task.attachments);
    }
  }, [task]);

  const getFileIcon = (fileType, fileName) => {
    const fileIcons = {
      image: <FaFileImage />,
      pdf: <FaFilePdf />,
      word: <FaFileWord />,
      excel: <FaFileExcel />,
    };

    // Check fileType first
    if (fileType) {
      if (fileType.startsWith('image/')) return fileIcons.image;
      if (fileType === 'application/pdf') return fileIcons.pdf;
      if (fileType.includes('word')) return fileIcons.word;
      if (fileType.includes('excel') || fileType.includes('spreadsheet'))
        return fileIcons.excel;
    }

    // Fallback to fileName extension checks
    if (fileName) {
      if (
        fileName.includes('.jpeg') ||
        fileName.includes('.png') ||
        fileName.includes('.jpg')
      )
        return fileIcons.image;
      if (fileName.includes('.xlsx')) return fileIcons.excel;
      if (fileName.includes('.docx')) return fileIcons.word;
      if (fileName.includes('.pdf')) return fileIcons.pdf;
    }

    // Default fallback if no match
    return <FaFile />;
  };

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    const newSelectedFiles = files.map((file) => ({
      file,
      fileName: file.name,
      type: file.type,
      preview: file.type.startsWith('image/')
        ? URL.createObjectURL(file)
        : null,
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newSelectedFiles]);
  };

  const handleCommentImageDeletion = (type) => {
    if (commentImageDeleteType === 'comment') {
      handleRemoveCommentImage();
    } else {
      handleRemoveEditedCommentImage();
    }
    setIsCommentDeleteModalOpen(false);
  };

  const handleRemoveFile = () => {
    if (isAttachExisting) {
      const fileToRemove = attachments[removeIndex];
      setFilesToRemove((prev) => [...prev, fileToRemove]);
      setAttachments((prev) => prev.filter((_, i) => i !== removeIndex));
    } else {
      setSelectedFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((_, i) => i !== removeIndex);
        if (prevFiles[removeIndex]?.preview) {
          URL.revokeObjectURL(prevFiles[removeIndex].preview);
        }
        return updatedFiles;
      });
    }
    setIsAttachDeleteModalOpen(false);
  };

  const handleDownloadAttachment = (url, fileName) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file. Please try again.');
    }
  };

  const renderFileThumbnail = (file, index, isExisting = false) => {
    return (
      <div
        key={index}
        className='relative border rounded p-1 w-[100px] h-[100px] flex flex-col'
      >
        {file.preview ? (
          <img
            src={file.preview}
            alt={file.fileName}
            className='w-full h-[80px] object-cover'
          />
        ) : (
          <div className='w-full h-[80px] flex items-center justify-center bg-gray-100'>
            {getFileIcon(file.type, file.fileName)}
          </div>
        )}
        <div className='mt-1 text-xs truncate flex-grow'>{file.fileName}</div>
        <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-20'>
          {isExisting && !isEditing && (
            <button
              onClick={() => handleDownloadAttachment(file.url, file.fileName)}
              className='text-white hover:text-blue-300 p-2 rounded-full transition-colors duration-200'
            >
              <FaDownload size={24} />
            </button>
          )}
          {isEditing && (
            <button
              onClick={() => {
                setIsAttachDeleteModalOpen(true);
                setRemoveIndex(index);
                setIsAttachExisting(isExisting);
              }}
              className='text-white hover:text-red-300 p-2 rounded-full transition-colors duration-200'
            >
              <FaTrash size={24} />
            </button>
          )}
        </div>
      </div>
    );
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsLoading(true);
    try {
      const id = taskId.toString();
      const globalTaskPath = 'task_list';

      await deleteTask(globalTaskPath, id);

      setIsLoading(false);
      toast.success('Task deleted successfully!');
      navigate('/task-list');
    } catch (error) {
      setIsLoading(false);
      console.error('Error deleting task:', error);
      toast.error('Failed to delete the task. Please try again.');
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      let updatedEndTime = new Date(endTime);

      // Validate files
      if (!validateFiles(selectedFiles)) {
        setIsLoading(false);
        return;
      }

      let assignedTo = '';
      let assignedDeveloperFirstName = '';
      let assignedDeveloperLastName = '';
      let assignedToRole = null;
      let assigned = task.assigned;

      if (editedTask.assigned_to !== task.assigned_to) {
        // Lead assigned to someone else
        const developerDoc = await getDoc(
          doc(db, 'user_details', editedTask.assigned_to)
        );

        if (task.creator_email !== editedTask.assigned_to) {
          assigned = true;
        }

        if (developerDoc.exists()) {
          const developerData = developerDoc.data();
          assignedTo = editedTask.assigned_to;
          assignedDeveloperFirstName = developerData.first_name || '';
          assignedDeveloperLastName = developerData.last_name || '';
          assignedToRole = developerData.role || null;
        }
      } else {
        assignedTo = task.assigned_to;
        assignedDeveloperFirstName = task.assigned_to_first_name || '';
        assignedDeveloperLastName = task.assigned_to_last_name || '';
        assignedToRole = task.assigned_to_role || null;
      }

      try {
        // Handle file uploads
        let newAttachments = [];
        if (selectedFiles.length > 0) {
          const result = await uploadTaskFiles(selectedFiles, taskId);
          newAttachments = result.files.map((file) => ({
            fileName: file.fileName,
            url: file.url,
          }));
        }

        // Combine existing attachments (excluding removed ones) with new attachments
        const updatedAttachments = [
          ...attachments.filter((att) => !filesToRemove.includes(att)),
          ...newAttachments,
        ];
        const globalTaskPath = 'task_list';

        if (
          editedTask.status.toLowerCase() === 'completed' &&
          task.status.toLowerCase() !== 'completed'
        ) {
          updatedEndTime = new Date();
          if (startTime && updatedEndTime) {
            if (updatedEndTime <= new Date(startTime)) {
              toast.error(
                'Task cannot be completed as the start date is set to a future date. Please adjust the start date.'
              );
              return;
            }
          }
        }

        // Add updated_at field to the editedTask
        const updatedTask = {
          ...editedTask,
          assigned_to: assignedTo,
          assigned_to_first_name: assignedDeveloperFirstName,
          assigned_to_last_name: assignedDeveloperLastName,
          assigned,
          assigned_to_role: assignedToRole || null,
          updated_at: new Date(),
          end_time: endTime ? updatedEndTime : '',
          estimation_unit: `${estimationUnit}`,
          start_time: startTime ? new Date(startTime) : '',
          estimation: `${editedTask.estimation}`,
          attachments: updatedAttachments,
        };


        // Update in global task list
        await updateTask(globalTaskPath, taskId, updatedTask, '');

        setTask(updatedTask);
        setAttachments(updatedAttachments);
        setSelectedFiles([]);
        setFilesToRemove([]);
        setIsEditing(false);
        setIsLoading(false);
        toast.success('Task updated successfully!');
        setErrors({});
      } catch (error) {
        setIsLoading(false);
        toast.error('Failed to update task!');
        console.error('Error updating task:', error);
      }
    }
  };

  const handleCancel = () => {
    setEditedTask(task);
    setIsEditing(false);
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'estimation') {
      // Allow only numbers and one decimal point
      const regex = /^\d*\.?\d*$/;
      if (regex.test(value) || value === '') {
        setEditedTask((prev) => ({ ...prev, [name]: value }));
      }
    } else if (name === 'estimationUnit') {
      setEstimationUnit(value);
    } else if (name === 'start_time' || name === 'end_time') {
      validateDates(name, value);
    } else {
      setEditedTask((prev) => ({ ...prev, [name]: value }));
    }
    if (
      name === 'start_time' ||
      name === 'estimation' ||
      name === 'estimationUnit'
    ) {
      calculateEndTime(name, value);
    }
    // Clear the error for this field when the user starts typing
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const calculateEndTime = (name, value) => {
    // Get start time and validate
    const start = name === 'start_time' ? value : startTime;
    if (!start) {
      console.error('Start time is missing, cannot calculate end time.');
      return;
    }

    // Get estimation and validate
    const estimation =
      name === 'estimation' ? parseFloat(value) : parseFloat(task.estimation);
    if (isNaN(estimation)) {
      console.error('Invalid estimation value:', estimation);
      return;
    }

    // Use state estimation unit if not updated by `name`
    const estimationUnitLocal =
      name === 'estimationUnit' ? value : estimationUnit;
    if (!['h', 'd', 'w'].includes(estimationUnit)) {
      console.error('Invalid estimation unit:', estimationUnit);
      return;
    }

    // Calculate end date
    const startDate = new Date(start);
    let endDate = new Date(startDate);

    const timeAdjustments = {
      h: estimation * 60, // Hours to minutes
      d: estimation * 24 * 60, // Days to minutes
      w: estimation * 7 * 24 * 60, // Weeks to minutes
    };

    const adjustment = timeAdjustments[estimationUnitLocal];
    if (adjustment !== undefined) {
      endDate.setMinutes(endDate.getMinutes() + Math.round(adjustment));
    } else {
      console.warn('Unsupported estimation unit:', estimationUnitLocal);
      return;
    }

    // Convert to local ISO string
    const endDateLocal = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    );
    const endDateString = endDateLocal.toISOString().slice(0, 16);

    setEndTime(endDateString);
  };

  const validateDates = (changedField, value) => {
    const start = changedField === 'start_time' ? value : startTime;
    const end = changedField === 'end_time' ? value : endTime;
    if (start && end) {
      if (new Date(end) <= new Date(start) && changedField === 'end_time') {
        toast.error('End date must be after the start date');
      } else {
        setStartTime(start);
        setEndTime(end);
      }
    } else {
      setStartTime(start);
    }
  };

  const handleBackClick = () => {
    if (location.state && location.state.from === 'notifications') {
      navigate('/notifications');
    } else {
      navigate('/task-list');
    }
  };

  const formatDate = (dateInput) => {
    if (!dateInput) return 'Not set'; // Return a default value if dateInput is null or undefined

    let date;
    if (dateInput instanceof Date) {
      date = dateInput;
    } else if (typeof dateInput === 'object' && dateInput.seconds) {
      // Handle Firestore Timestamp object
      date = new Date(dateInput.seconds * 1000);
    } else if (typeof dateInput === 'string' || typeof dateInput === 'number') {
      date = new Date(dateInput);
    } else {
      console.error('Invalid date format:', dateInput);
      return 'Invalid Date';
    }

    if (isNaN(date.getTime())) {
      console.error('Invalid date:', dateInput);
      return 'Invalid Date';
    }

    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleDateString('en-US', options);
  };

  function formatDateForInput(date) {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    return d.toISOString().slice(0, 16);
  }

  const validateFiles = (files, isCommentImage = false) => {
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    const maxFiles = isCommentImage ? 1 : 10; // 1 file for comment image, 10 for task attachments

    if (!isCommentImage && files.length > maxFiles) {
      toast.error(`You can upload a maximum of ${maxFiles} files.`);
      return false;
    }

    for (let i = 0; i < files.length; i++) {
      const file = isCommentImage ? files[i] : files[i].file;
      if (file.size > maxFileSize) {
        toast.error(`File "${file.name}" exceeds the 2MB size limit.`);
        return false;
      }
    }

    return true;
  };

  const validateCommentImage = (file) => {
    return validateFiles([file], true);
  };

  const handleSaveEditedComment = async (commentId) => {
    if (!editedCommentText.trim() && !editedCommentImage) {
      toast.error('Comment or image is required.');
      return;
    }

    if (!editedCommentText.trim()) {
      toast.error('Comment cannot be empty.');
      return;
    }

    try {
      let imageUrl = null;
      if (editedCommentImage instanceof File) {
        const formData = new FormData();
        formData.append('comment_image', editedCommentImage);
        formData.append('taskId', taskId);

        const response = await api.post('/api/upload-comment-image', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        imageUrl = response.data.imageUrl;
      } else if (typeof editedCommentImage === 'string') {
        imageUrl = editedCommentImage;
      }

      const updatedComments = task.comments.map((comment) =>
        comment.id === commentId
          ? {
            ...comment,
            comment: editedCommentText.trim(),
            imageUrl: imageUrl,
            updated_at: new Date().toISOString(),
            is_edited: true
          }
          : comment
      );

      const updatedTask = { ...task, comments: updatedComments };
      const globalTaskPath = 'task_list';

      await updateTask(globalTaskPath, taskId, updatedTask);

      setTask(updatedTask);
      setEditingCommentId(null);
      setEditedCommentText('');
      setEditedCommentImage(null);
      setEditedCommentImagePreview(null);
      toast.success('Comment updated successfully!');
    } catch (error) {
      toast.error('Failed to update comment. Please try again.');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!newComment.trim() && !commentImage) {
      toast.error('Comment or image is required.');
      return;
    }

    if (!newComment.trim()) {
      setIsLoading(false);
      toast.error('Comment cannot be empty.');
      return;
    }

    if (!user) {
      console.error('User not authenticated');
      return;
    }

    let imageUrl = null;
    if (commentImage) {
      const formData = new FormData();
      formData.append('comment_image', commentImage);
      formData.append('taskId', taskId);

      const response = await api.post('/api/upload-comment-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      imageUrl = response.data.imageUrl;
    }

    const id = Math.random().toString(36).substr(2, 9);
    const comment = {
      id,
      creator_email: user.email || '',
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      comment: newComment,
      created_at: new Date().toISOString(), // Store as ISO string for consistency
      imageUrl: imageUrl,
    };

    try {
      const globalTaskPath = 'task_list';

      // Update global task list
      await updateTask(globalTaskPath, taskId, null, comment);

      // Update local state to add the new comment at the beginning of the array
      setTask((prevTask) => ({
        ...prevTask,
        comments: [...(prevTask.comments || []), comment],
      }));
      toast.success('comment added successfully!');
      setNewComment('');
      setCommentImage(null);
      setCommentImagePreview(null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error('comment added Failed , Please try again');
    }
  };

  const renderCommentImage = (imageUrl) => {
    return (
      <div className='mt-2'>
        <img
          src={imageUrl}
          alt='Comment attachment'
          className='h-20 w-20 object-cover rounded cursor-pointer'
          onClick={() => setExpandedImage(imageUrl)}
        />
      </div>
    );
  };

  const renderExpandedImage = () => {
    if (!expandedImage) return null;

    return (
      <div
        className='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50'
        onClick={() => setExpandedImage(null)}
      >
        <div className='max-w-3xl max-h-3xl'>
          <img
            src={expandedImage}
            alt='Expanded comment attachment'
            className='max-w-full max-h-full object-contain'
          />
        </div>
      </div>
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  if (!task && !isLoading)
    return (
      <div className='flex justify-center items-center h-screen'>
        Task not found
      </div>
    );

  const sortComments = (comments) => {
    return [...comments].sort((a, b) => {
      // Get the most recent timestamp for each comment (either updated_at or created_at)
      const getLatestTimestamp = (comment) => {
        if (comment.updated_at) {
          return typeof comment.updated_at === 'string'
            ? new Date(comment.updated_at)
            : new Date(comment.updated_at.seconds * 1000);
        }
        return typeof comment.created_at === 'string'
          ? new Date(comment.created_at)
          : new Date(comment.created_at.seconds * 1000);
      };

      const timestampA = getLatestTimestamp(a);
      const timestampB = getLatestTimestamp(b);

      // Sort in descending order (newest first)
      return timestampB - timestampA;
    });
  };

  return (
    <Layout>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        theme='light'
      />
      <div className='flex w-full h-[calc(100vh-4rem)]'>
        <SideMenu />
        <div className='flex-1 overflow-hidden'>
          {isLoading ? (
            <div className='flex justify-center items-center h-full'>
              <Loader />
            </div>
          ) : (
            <>
              <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
                <h2 className='text-2xl font-bold text-white'>Task Details</h2>
              </div>
              <div className='p-6 bg-gray-100 h-[calc(100vh-4rem-3.5rem)] overflow-y-auto'>
                <div className='bg-white rounded-lg shadow-lg p-6'>
                  <div className='flex items-center justify-between mb-6'>
                    <div className='flex items-center'>
                      <button
                        onClick={handleBackClick}
                        className='mr-4 text-orange-500 hover:text-orange-600 focus:outline-none'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-6 w-6'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M10 19l-7-7m0 0l7-7m-7 7h18'
                          />
                        </svg>
                      </button>
                      <h2 className='text-2xl font-bold text-gray-900'>
                        {task?.id}
                      </h2>
                    </div>
                    <div className='flex space-x-2'>
                      {!isEditing &&
                        task.status.toLowerCase() !== 'completed' && (
                          <button
                            onClick={handleEdit}
                            className='bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                          >
                            Edit
                          </button>
                        )}

                      {!isEditing && (
                        <button
                          onClick={handleDeleteClick}
                          className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Task details form */}
                  <div className='space-y-6'>
                    {/* ID and Project Name */}
                    <div className='flex space-x-4'>
                      <div className='w-1/2'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          ID
                        </label>
                        <input
                          type='text'
                          value={task.id}
                          disabled
                          className='w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500'
                        />
                      </div>
                      <div className='w-1/2'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          Project
                        </label>
                        <input
                          type='text'
                          value={capitalizeFirstLetter(task.project_name)}
                          disabled
                          className='w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500'
                        />
                      </div>
                    </div>

                    {/* Title */}
                    <div>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        Title
                      </label>
                      <input
                        type='text'
                        name='title'
                        value={isEditing ? editedTask.title : task.title}
                        onChange={handleChange}
                        disabled={!isEditing}
                        className={`w-full px-3 py-2 ${isEditing ? 'bg-white' : 'bg-gray-100'
                          } text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 ${errors.title ? 'border-red-500' : ''
                          }`}
                      />
                      {errors.title && (
                        <p className='text-red-500 text-xs mt-1'>
                          {errors.title}
                        </p>
                      )}
                    </div>

                    {/* Description */}
                    <div>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        Description
                      </label>
                      <textarea
                        name='description'
                        value={
                          isEditing ? editedTask.description : task.description
                        }
                        onChange={handleChange}
                        disabled={!isEditing}
                        rows='3'
                        className={`w-full px-3 py-2 ${isEditing ? 'bg-white' : 'bg-gray-100'
                          } text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 ${errors.description ? 'border-red-500' : ''
                          }`}
                      />
                      {errors.description && (
                        <p className='text-red-500 text-xs mt-1'>
                          {errors.description}
                        </p>
                      )}
                    </div>

                    {/* Status and Estimation */}
                    <div className='flex space-x-4'>
                      <div className='w-1/2'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          Status
                        </label>
                        {isEditing ? (
                          <select
                            name='status'
                            value={editedTask.status}
                            onChange={handleChange}
                            className={`w-full px-3 py-2 bg-white text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 ${errors.status ? 'border-red-500' : ''
                              }`}
                          >
                            {statusOptions.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type='text'
                            value={task.status}
                            disabled
                            className='w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500'
                          />
                        )}
                        {errors.status && (
                          <p className='text-red-500 text-xs mt-1'>
                            {errors.status}
                          </p>
                        )}
                      </div>
                      <div className='w-1/2'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          Estimation
                        </label>
                        <div className='flex space-x-2'>
                          <input
                            type='text'
                            name='estimation'
                            value={editedTask.estimation}
                            onChange={handleChange}
                            required
                            disabled={!isEditing}
                            className={`w-full px-3 py-2 ${isEditing ? 'bg-white' : 'bg-gray-100'
                              } text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500 ${errors.estimation ? 'border-red-500' : ''
                              }`}
                          />
                          <select
                            name='estimationUnit'
                            value={estimationUnit}
                            onChange={handleChange}
                            disabled={!isEditing}
                            className='w-1/3 px-3 py-2 bg-white text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500'
                          >
                            <option value='h'>Hours</option>
                            <option value='d'>Days</option>
                            <option value='w'>Weeks</option>
                          </select>
                        </div>
                        {errors.estimation && (
                          <p className='text-red-500 text-xs mt-1'>
                            {errors.estimation}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Start Time and End Time */}
                    <div className='flex space-x-4'>
                      <div className='w-1/2'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          Start Time
                        </label>
                        {isEditing ? (
                          <input
                            type='datetime-local'
                            name='start_time'
                            value={startTime}
                            onChange={handleChange}
                            className='w-full px-3 py-2 bg-white text-gray-700 border rounded-md focus:outline-none'
                          />
                        ) : (
                          <input
                            type='text'
                            value={formatDate(task.start_time)}
                            disabled
                            className='w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md focus:outline-none'
                          />
                        )}
                      </div>
                      <div className='w-1/2'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          End Time
                        </label>
                        {isEditing ? (
                          <input
                            type='datetime-local'
                            name='end_time'
                            value={endTime}
                            onChange={handleChange}
                            className='w-full px-3 py-2 bg-white text-gray-700 border rounded-md focus:outline-none'
                          />
                        ) : (
                          <input
                            type='text'
                            value={
                              task.end_time
                                ? formatDate(task.end_time)
                                : 'Not set'
                            }
                            disabled
                            className='w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md focus:outline-none'
                          />
                        )}
                      </div>
                    </div>

                    {/* Assigned by and Assigned to */}
                    <div className='flex space-x-4'>
                      <div className='w-1/2'>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          Assigned by
                        </label>
                        <input
                          type='text'
                          value={task.creator_email || 'Not assigned'}
                          disabled
                          className='w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500'
                        />
                      </div>
                      <div className='w-1/2'>
                        <div>
                          <label
                            className='block text-gray-700 text-sm font-bold mb-2'
                            htmlFor='assigned_to'
                          >
                            Assign To
                          </label>
                          {isEditing ? <select
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            id='assigned_to'
                            name='assigned_to'
                            value={editedTask.assigned_to}
                            onChange={handleChange}
                            required
                          >
                            <option value=''>Select a user</option>
                            {developers.map((developer, index) => (
                              <option key={index} value={developer.email}>
                                {developer.name}
                              </option>
                            ))}
                          </select> : <input
                            type='text'
                            value={task.assigned_to || 'Not assigned'}
                            disabled
                            className='w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500'
                          />}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='mt-6'>
                    <h3 className='text-lg font-semibold mb-2'>Attachments</h3>
                    <div className='grid grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-1'>
                      {attachments.map((attachment, index) =>
                        renderFileThumbnail(attachment, index, true)
                      )}
                      {isEditing &&
                        selectedFiles.map((file, index) =>
                          renderFileThumbnail(file, index)
                        )}
                    </div>
                  </div>

                  {isEditing && (
                    <div className='mt-4'>
                      <input
                        type='file'
                        ref={fileInputRef}
                        onChange={handleFileSelection}
                        multiple
                        className='hidden'
                      />
                      <button
                        onClick={() => fileInputRef.current.click()}
                        className='bg-white text-orange-500 border border-orange-500 px-4 py-2 rounded-lg hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 font-semibold transition duration-150 ease-in-out'
                      >
                        <FaUpload className='inline mr-2' /> Select Files
                      </button>
                      <p className='text-sm text-gray-600 mt-2'>
                        Note: Maximum 10 files allowed. Each file must be 2MB or
                        less.
                      </p>
                    </div>
                  )}

                  {isEditing && (
                    <div className='flex justify-end space-x-4 mt-6'>
                      <button
                        onClick={handleSave}
                        className='bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancel}
                        className='bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2'
                      >
                        Cancel
                      </button>
                    </div>
                  )}

                  {/* Comments Section */}
                  <div className='mt-8 bg-gray-50 p-6 rounded-lg shadow-md'>
                    <h3 className='text-xl font-bold mb-4 text-orange-500'>
                      Comments
                    </h3>
                    <form onSubmit={handleCommentSubmit} className='mb-6'>
                      <textarea
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className='w-full px-4 py-3 text-gray-800 border border-orange-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-300 focus:border-orange-300 font-medium bg-white shadow-sm'
                        rows='4'
                        placeholder='Add a comment...'
                      ></textarea>
                      <div className='mt-2'>
                        <input
                          type='file'
                          ref={commentImageInputRef}
                          onChange={handleCommentImageSelection}
                          accept='image/jpg,image/jpeg,image/png'
                          className='hidden'
                        />
                        <button
                          type='button'
                          onClick={() => commentImageInputRef.current.click()}
                          className='bg-white text-orange-500 border border-orange-500 px-4 py-2 rounded-lg hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 font-semibold transition duration-150 ease-in-out'
                        >
                          <FaUpload className='inline mr-2' /> Attach Image
                        </button>
                      </div>
                      {commentImagePreview && (
                        <div className='mt-2 relative inline-block'>
                          <img
                            src={commentImagePreview}
                            alt='Comment attachment'
                            className='h-20 w-20 object-cover rounded'
                          />
                          <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-30'>
                            <button
                              type='button'
                              onClick={() => {
                                setCommentImageDeleteType('comment');
                                setIsCommentDeleteModalOpen(true);
                              }}
                              className='text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-200'
                            >
                              <FaTrash size={16} />
                            </button>
                          </div>
                        </div>
                      )}
                      <br></br>
                      <div className='mt-3 flex justify-end'>
                        <button
                          type='submit'
                          className='bg-gradient-to-r from-orange-500 to-orange-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 font-semibold transition duration-150 ease-in-out'
                        >
                          Add Comment
                        </button>
                      </div>
                    </form>
                    {/* {(!task.comments || task.comments.length === 0) && (
                      <div className='text-center py-8'>
                        <div className='w-12 h-12 text-gray-300 mx-auto mb-3' />
                        <p className='text-gray-500'>
                          No comments yet. Be the first to share your thoughts!
                        </p>
                      </div>
                    )} */}

                    <div className='space-y-4'>
                      {task.comments && task.comments.length > 0 ? (
                        sortComments(task.comments).map((comment, index) => (
                          <div
                            key={index}
                            className='bg-white p-4 rounded-lg shadow-sm border-l-4 border-orange-500'
                          >
                            <div className='flex items-center justify-between mb-2'>
                              <div>
                                <p className='text-sm font-semibold text-orange-600'>
                                  {comment.first_name} {comment.last_name}
                                </p>
                                <p className='text-xs text-gray-500 opacity-75'>
                                  {comment.creator_email}
                                </p>
                              </div>
                              <div className='flex items-center'>
                                <div className='text-xs text-gray-500 mr-2'>
                                  <span>{formatDate(comment.created_at)}</span>
                                  {comment.is_edited && (
                                    <>
                                      <span className="mx-1">•</span>
                                      <span className="italic">
                                        edited {formatDate(comment.updated_at)}
                                      </span>
                                    </>
                                  )}
                                </div>
                                {comment.creator_email === user.email && (
                                  <button
                                    onClick={() =>
                                      handleEditComment(
                                        comment.id,
                                        comment.comment,
                                        comment.imageUrl
                                      )
                                    }
                                    className='text-blue-500 hover:text-blue-700'
                                  >
                                    <FaEdit />
                                  </button>
                                )}
                              </div>
                            </div>
                            {editingCommentId === comment.id ? (
                              <div>
                                <textarea
                                  value={editedCommentText}
                                  onChange={(e) =>
                                    setEditedCommentText(e.target.value)
                                  }
                                  className='w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none'
                                  rows='3'
                                />
                                <div className='mt-2'>
                                  <input
                                    type='file'
                                    ref={editCommentImageInputRef}
                                    onChange={handleEditCommentImageSelection}
                                    accept='image/jpg,image/jpeg,image/png'
                                    className='hidden'
                                  />
                                  <button
                                    type='button'
                                    onClick={() =>
                                      editCommentImageInputRef.current.click()
                                    }
                                    className='bg-white text-orange-500 border border-orange-500 px-4 py-2 rounded-lg hover:bg-orange-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 font-semibold transition duration-150 ease-in-out'
                                  >
                                    <FaUpload className='inline mr-2' />
                                    {editedCommentImage
                                      ? 'Change Image'
                                      : 'Add Image'}
                                  </button>
                                </div>
                                {editedCommentImagePreview && (
                                  <div className='mt-2 relative inline-block'>
                                    <img
                                      src={editedCommentImagePreview}
                                      alt='Comment attachment'
                                      className='h-20 w-20 object-cover rounded'
                                    />
                                    <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-30'>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          setCommentImageDeleteType(
                                            'edit-comment'
                                          );
                                          setIsCommentDeleteModalOpen(true);
                                        }}
                                        className='text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-200'
                                      >
                                        <FaTrash size={16} />
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <div className='mt-2 flex justify-end space-x-2'>
                                  <button
                                    onClick={() =>
                                      handleSaveEditedComment(comment.id)
                                    }
                                    className='bg-orange-500 text-white px-3 py-1 rounded hover:bg-orange-600'
                                  >
                                    Save
                                  </button>
                                  <button
                                    onClick={() => {
                                      setEditingCommentId(null);
                                      setEditedCommentImage(null);
                                      setEditedCommentImagePreview(null);
                                    }}
                                    className='bg-gray-300 text-gray-700 px-3 py-1 rounded hover:bg-gray-400'
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                <p className='text-gray-800 text-base mt-2'>
                                  {comment.comment}
                                </p>
                                {comment.imageUrl &&
                                  renderCommentImage(comment.imageUrl)}
                              </>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className='text-center py-8'>
                          <div className='w-12 h-12 text-gray-300 mx-auto mb-3' />
                          <p className='text-gray-500'>
                            {/* No comments yet. Be the first to share your thoughts! */}
                          </p>
                        </div>
                      )
                      }
                    </div>
                  </div>

                  {renderExpandedImage()}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
      <DeleteConfirmationModal
        isOpen={isAttachDeleteModalOpen}
        onClose={() => setIsAttachDeleteModalOpen(false)}
        onConfirm={() => handleRemoveFile()}
        type={'project-delete'}
        message={`Are you sure you want to remove the attachment?`}
      />
      <DeleteConfirmationModal
        isOpen={isCommentDeleteModalOpen}
        onClose={() => setIsCommentDeleteModalOpen(false)}
        onConfirm={() => handleCommentImageDeletion()}
        type={'project-delete'}
        message={`Are you sure you want to remove the image?`}
      />
    </Layout>
  );
};

export default TaskDetails;

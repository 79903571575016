import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs, arrayUnion, arrayRemove, serverTimestamp } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSort, FaPlus, FaTrash } from 'react-icons/fa';
import Loader from '../common/Loader';
import { useAuth } from '../../context/AuthContext';
import Layout from '../common/Layout';
import SideMenu from '../common/SideMenu';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import { fetchTaskLifestyleConfig } from '../../firebase/firetstore';

const CreateProjects = () => {
  const [showInputs, setShowInputs] = useState(false);
  const [projectTitle, setProjectTitle] = useState('');
  const [projectStatus, setProjectStatus] = useState('');
  const [projects, setProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [assignProject, setAssignProject] = useState('');
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [projectToRemove, setProjectToRemove] = useState(null);
  const [projectPrefix, setProjectPrefix] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.email) {
      fetchProjects();
      fetchAllProjects();
      fetchProjectStatuses();
    }
  }, [user]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const db = getFirestore();
      const userRef = doc(db, 'user_details', user.email);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userProjects = userData.projects || [];

        const projectsRef = collection(db, 'projects');
        const projectsQuery = query(projectsRef, where('project_name', 'in', userProjects));
        const projectsSnapshot = await getDocs(projectsQuery);

        const projectsList = projectsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setProjects(projectsList);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      toast.error('Failed to fetch projects');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllProjects = async () => {
    try {
      const db = getFirestore();
      const projectsRef = collection(db, 'projects');
      const projectsSnapshot = await getDocs(projectsRef);

      const allProjectsList = projectsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setAllProjects(allProjectsList);
    } catch (error) {
      console.error('Error fetching all projects:', error);
      toast.error('Failed to fetch all projects');
    }
  };

  const fetchProjectStatuses = async () => {
    try {
      const db = getFirestore();
      const configDoc = await getDoc(doc(db, 'configs', 'project_life_cycle'));
      if (configDoc.exists()) {
        const statuses = configDoc.data().project_life_cycle || [];
        setProjectStatuses(statuses);
        if (statuses.length > 0) {
          setProjectStatus(statuses[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching project statuses:', error);
      toast.error('Failed to fetch project statuses');
    }
  };

  const handleCreateProject = () => {
    setShowInputs(true);
    setProjectTitle('');
    setProjectStatus(projectStatuses[0] || '');
  };

  // Update the handleSave function to include the prefix
  const handleSave = async () => {
    if (!projectTitle || !projectStatus || !projectPrefix) {
      toast.error('Please fill in all fields: Project Title, Status, and Prefix');
      return;
    }
    // Validate project prefix
    if (projectPrefix.length < 2 || projectPrefix.length > 3 || !/^[A-Z]+$/.test(projectPrefix)) {
      toast.error('Project Prefix must be 2 to 3 uppercase letters');
      return;
    }

    const normalizedProjectTitle = projectTitle.toLowerCase().trim();
    try {
      const db = getFirestore();
      const projectsRef = collection(db, 'projects');

      // Check for existing project with same name or prefix
      const nameQuery = query(projectsRef, where("project_name", "==", normalizedProjectTitle.toLowerCase()));
      const prefixQuery = query(projectsRef, where("project_prefix", "==", projectPrefix));
      const [nameSnapshot, prefixSnapshot] = await Promise.all([getDocs(nameQuery), getDocs(prefixQuery)]);

      if (!nameSnapshot.empty) {
        toast.error('A project with this name already exists');
        return;
      }
      if (!prefixSnapshot.empty) {
        toast.error('A project with this prefix already exists');
        return;
      }
      // Create new project with incremental ID
      const taskIdConfig = await fetchTaskLifestyleConfig('configs', 'task_id');
      let currentProjectId = (taskIdConfig.project_id || 0) + 1;
      const newProjectRef = doc(db, 'projects', currentProjectId.toString());
      await setDoc(newProjectRef, {
        id: currentProjectId,
        task_id: 0,
        project_name: normalizedProjectTitle.toLowerCase(),
        project_status: projectStatus,
        project_prefix: projectPrefix,
        created_at: serverTimestamp(),
        created_by: user.email
      });

      // Update the project_id in the task_id document
      const taskIdRef = doc(db, 'configs', 'task_id');
      await setDoc(taskIdRef, { project_id: currentProjectId }, { merge: true });

      // Add the project to the user's projects list
      const userRef = doc(db, 'user_details', user.email);
      await updateDoc(userRef, {
        projects: arrayUnion(normalizedProjectTitle)
      });

      toast.success('Project created successfully!');
      setProjectTitle('');
      setProjectPrefix('');
      setProjectStatus(projectStatuses[0] || '');
      setShowInputs(false);
      fetchProjects();
      fetchAllProjects();
    } catch (error) {
      console.error('Error saving project:', error);
      toast.error('Failed to save project. Please try again.');
    }
  };

  const handleCancel = () => {
    setShowInputs(false);
    setProjectTitle('');
    setProjectStatus(projectStatuses[0] || '');
  };

  const handleRemoveClick = (projectName) => {
    setProjectToRemove(projectName);
    setIsRemoveModalOpen(true);
  };

  const handleRemoveConfirm = async () => {
    if (projectToRemove) {
      try {
        const db = getFirestore();
        const userRef = doc(db, 'user_details', user.email);
        await updateDoc(userRef, {
          projects: arrayRemove(projectToRemove)
        });

        toast.success('Project removed successfully!');
        fetchProjects();
      } catch (error) {
        console.error('Error removing project:', error);
        toast.error('Failed to remove project. Please try again.');
      } finally {
        setIsRemoveModalOpen(false);
        setProjectToRemove(null);
      }
    }
  };

  const handleAssignClick = () => {
    if (!assignProject) {
      toast.error('Please select a project to assign');
      return;
    }
    setIsAssignModalOpen(true);
  };

  const handleAssignConfirm = async () => {
    try {
      const db = getFirestore();
      const userRef = doc(db, 'user_details', user.email);
      await updateDoc(userRef, {
        projects: arrayUnion(assignProject)
      });

      toast.success('Project assigned successfully!');
      setAssignProject('');
      fetchProjects();
    } catch (error) {
      console.error('Error assigning project:', error);
      toast.error('Failed to assign project. Please try again.');
    } finally {
      setIsAssignModalOpen(false);
    }
  };

  const handleStatusChange = async (projectId, projectStatus) => {
    try {
      setIsLoading(true)
      const db = getFirestore();
      const projectRef = doc(db, 'projects', projectId.toString());
      // Fetch the current project data
      const projectSnapshot = await getDoc(projectRef);
      if (!projectSnapshot.exists()) {
        toast.error('Project not found');
        return;
      }
      const currentProject = projectSnapshot.data();
      if (projectStatus.toLowerCase() === 'in-active' && currentProject.project_status.toLowerCase() !== 'in-active') {
        // Fetch tasks related to this project
        const tasksRef = collection(db, 'task_list');
        const q = query(tasksRef, where("project_name", "==", currentProject.project_name));
        const querySnapshot = await getDocs(q);
        const openTasks = querySnapshot.docs.filter(doc => {
          const taskStatus = doc.data().status.toLowerCase();
          return taskStatus === 'open' || taskStatus === 'in progress';
        });
        if (openTasks.length > 0) {
          setIsLoading(false)
          toast.warning('There are open or in-progress tasks associated with this project. Please close all tasks before inactivating the project.');
          return;
        }
      }
      await updateDoc(projectRef, {
        project_status: projectStatus,
        updated_at: serverTimestamp()
      });
      toast.success('Project status updated successfully!');
      fetchProjects();
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error updating project status:', error);
      toast.error('Failed to update project status. Please try again.');
    }
  };

  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    const sortedProjects = [...projects].sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a.project_name.localeCompare(b.project_name);
      } else {
        return b.project_name.localeCompare(a.project_name);
      }
    });
    setProjects(sortedProjects);
  };

  return (
    <Layout>
      <ToastContainer autoClose={3000} />
      <div className="flex w-full h-[calc(100vh-4rem)]">
        <SideMenu />
        <div className="flex-1 overflow-y-auto">
          <div className='h-14 flex items-center justify-between px-6 border-b border-gray-200 bg-gradient-to-r from-orange-500 to-orange-600'>
            <h1 className='font-semibold text-xl text-white'>Project Management</h1>
          </div>
          <div className='p-6 bg-gray-50'>
            <div className="flex justify-between items-center mb-6">
              <button
                onClick={handleCreateProject}
                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                <FaPlus className="mr-2" /> Create New Project
              </button>
              <div className="flex items-center space-x-2">
                <select
                  value={assignProject}
                  onChange={(e) => setAssignProject(e.target.value)}
                  className="p-2 border border-gray-300 rounded w-64"
                >
                  <option className="p-2 text-sm font-semibold text-gray-600" value="">Select a project to assign</option>
                  {allProjects.filter(p => !projects.some(up => up.id === p.id)).map((project) => (
                    <option key={project.id} value={project.project_name}>
                      {capitalizeFirstLetter(project.project_name)}
                    </option>
                  ))}
                </select>
                <button
                  onClick={handleAssignClick}
                  className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded flex items-center"
                >
                  Assign Project
                </button>
              </div>
            </div>
            {showInputs && (
              <div className="mb-6 p-4 bg-white rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-4">Create New Project</h2>
                <div className="flex space-x-2">
                  <input
                    type="text"
                    value={projectTitle}
                    onChange={(e) => setProjectTitle(e.target.value)}
                    placeholder="Project Title"
                    className="p-2 border border-gray-300 rounded flex-grow"
                  />
                  <input
                    type="text"
                    value={projectPrefix}
                    onChange={(e) => setProjectPrefix(e.target.value.toUpperCase())}
                    placeholder="Project Prefix"
                    className="p-2 border border-gray-300 rounded"
                    maxLength={3}
                  />
                  <select
                    value={projectStatus}
                    onChange={(e) => setProjectStatus(e.target.value)}
                    className="p-2 border border-gray-300 rounded"
                  >
                    {projectStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={handleSave}
                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleCancel}
                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <Loader />
              </div>
            ) : (
              <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="min-w-full">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={handleSort}>
                        Project Name <FaSort className="inline-block ml-1" />
                      </th>
                      <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prefix</th>
                      <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {projects.map((project, index) => (
                      <tr key={project.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="py-4 px-6 text-sm font-medium text-gray-900">{capitalizeFirstLetter(project.project_name)}</td>
                        <td className="py-4 px-6 text-sm text-gray-500">{project.project_prefix}</td>
                        <td className="py-4 px-6 text-sm text-gray-500">
                          <select
                            value={project.project_status}
                            onChange={(e) => handleStatusChange(project.id, e.target.value)}
                            className="p-1 border border-gray-300 rounded"
                          >
                            {projectStatuses.map((status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td className="py-4 px-6 text-sm font-medium">
                          <button
                            onClick={() => handleRemoveClick(project.project_name)}
                            className="text-red-600 hover:text-red-900 flex items-center"
                          >
                            <FaTrash className="mr-1" /> Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
        onConfirm={handleRemoveConfirm}
        type={"project-delete"}
        message={`Are you sure you want to delete the project "${projectToRemove}"?`}
      />
      <DeleteConfirmationModal
        isOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        onConfirm={handleAssignConfirm}
        type={"project-assign"}
        message={`Are you sure you want to assign the project "${assignProject}" to you?`}
      />
    </Layout>
  );
};

export default CreateProjects;